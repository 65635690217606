import React, { useEffect, useState } from "react";
import axios from 'axios';
import Header from "../../header";
import Home2Footer from "./footer";
import { AutoComplete, Select, ConfigProvider, Form, Button, InputNumber, Steps } from "antd";
import {CheckOutlined, LoadingOutlined} from "@ant-design/icons"
import AOS from "aos";
import "aos/dist/aos.css";
import {Route, useHistory} from "react-router-dom";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import Home2Header from "./header";
import RxRequestForm from "./rxRequestForm";
import RxRequest from "./rxRequest";
import {medicalicon03, medicalicon04} from "../../imagepath";

const SearchMedication = (props) => {
  const authUser = useAuthUser();
  const isAuthenticated = useIsAuthenticated();

  const [medications, setMedications] = useState([]);
  const [medFormOption, setMedFormOption] = useState(null);
  const [medicationsLoading, setMedicationsLoading] = useState(false);
  // const [medStrengthOptions, setMedStrengthOptions] = useState(null);
  // const [medStrengthOptionsLoading, setMedStrengthOptionsLoading] = useState(false);

  const [searchLoading, setSearchLoading] = useState(false);
  const [searchMedicationName, setSearchMedicationName] = useState('');
  const [searchMedicationFullName, setSearchMedicationFullName] = useState('');
  const [foundPharmacies, setFoundPharmacies] = useState([]);
  const [foundOtherPharmacies, setFoundOtherPharmacies] = useState([]);
  const [searchZipcode, setSearchZipcode] = useState('');
  const [searchStateName, setSearchStateName] = useState('');

  const onRequestTransfer = (pharmacies) => {
    history.push(`/rx-request/${pharmacies.map((pharmacy) => pharmacy.id)}/${form.getFieldValue('drugStrength')}/${form.getFieldValue('drugQuantity')}/${searchZipcode}/${searchMedicationName}`);
  }

  AOS.init();
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });

    updateOptions(props.match.params.drugName);
  }, []);
  const handleScroll = () => {
    AOS.refresh();
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const updateOptions = (data) => {
    setMedicationsLoading(true);
    // setMedStrengthOptionsLoading(true);

    axios.get('https://rxnav.nlm.nih.gov/REST/drugs.json?name=' + data + '&expand=psn')
        .then(async (res) => {
          if (res.data.drugGroup.conceptGroup) {
            const rxcuis = res.data.drugGroup.conceptGroup.map((sgg) =>
                sgg.conceptProperties ? sgg.conceptProperties.map(cp => [cp.rxcui, cp.psn]) : []
            ).reduce(function(prev, next) {
              return prev.concat(next);
            });

            const searchResultMedications = [];
            for (let rxcui of rxcuis) {
              const resp = await axios.get('https://rxnav.nlm.nih.gov/REST/RxTerms/rxcui/' + rxcui[0] + '/allinfo.json')

              if (resp.data.rxtermsProperties) {
                searchResultMedications.push({...resp.data.rxtermsProperties, psn: rxcui[1]});
                console.log(resp.data);
              }
            }
            setMedications(searchResultMedications);

            setMedicationsLoading(false);
          }
        })
        .catch(err => console.log(err));
  };
  const [form] = Form.useForm();
  const history = useHistory();

  const onFinish = (values) => {
    console.log(values);

    setSearchLoading(true);

    axios.get(`/pharmacies/search.json?zipcode=${props.match.params.zipCode}&drug_rxcui=${values.drugStrength}&drug_quantity=${values.drugQuantity}`)
        .then(async (res) => {
          if (res.data) {
            console.log(res.data);

            setFoundPharmacies(res.data.in_state)
            setFoundOtherPharmacies(res.data.nearby_states)
            setSearchStateName(res.data.state_name)
          }

          const medication = medications.find((item) => item.rxcui === values.drugStrength);
          setSearchMedicationName(medication.psn);
          setSearchMedicationFullName(medication.psn);
          setSearchZipcode(props.match.params.zipCode)

          setSearchLoading(false);
        })
        .catch(err => console.log(err));
  };

  const medFormOptions = medications.filter(function(value, index, array) {
    return array.findIndex((i) => i.rxnormDoseForm === value.rxnormDoseForm) === index;})
      .map((j) => ({label: j.rxnormDoseForm, value: j.rxnormDoseForm}))
      .sort((a, b) => a.label.localeCompare(b.label));

  const medStrengthOptions = medications.filter((value, index, array) => value.rxnormDoseForm === medFormOption)
      .map((j) => ({label: j.psn, value: j.rxcui}))
      .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <div className="main-wrapper">
      {
        isAuthenticated ?
            <Header/> :
            <Home2Header/>
      }
      {/* Home Banner */}
      <section className="section section-search-medication">
        <div className="container-fluid">
          <div className="banner-wrapper">
            <div className="banner-header text-center aos" data-aos="fade-up">
              <h2>Enter your medication details</h2>
            </div>
            {/* Search */}
            <div className="search-box">
              <ConfigProvider
                  theme={{
                    components: {
                      Select: {
                        singleItemHeightLG: 52
                      },
                    },
                  }}>

                <Form
                    form={form}
                    name="control-hooks"
                    onFinish={onFinish}
                    // style={{ maxWidth: 600 }}
                    initialValues={{drugName: props.match.params.drugName}}
                >
                  <div className="form-group">
                    <div className="mb-3 search-medication-name aos" data-aos="fade-up">
                      <Form.Item
                          name="drugName"
                          rules={[{ required: true, message: "Name or brand is required" }]}
                          help="Ex : Wegovy">
                        <AutoComplete
                            disabled
                            style={{width: '100%', height: 'auto'}}
                            children={<input
                                type="text"
                                style={{fontSize: 16, height: 52, backgroundColor: 'rgba(0, 0, 0, 0.04)'}}
                                className="form-control"
                                placeholder="Generic Name, Brand Etc."/>}
                        />
                      </Form.Item>
                    </div>
                    <div className="mb-3 search-medication-form aos" data-aos="fade-up">
                      <Form.Item name="drugForm" rules={[{ required: true, message: "Medication form is required" }]}
                        help="Ex : Auto-Injector">
                        <Select
                          style={{width: '100%', minHeight: 46}}
                          loading={medicationsLoading}
                          disabled={medicationsLoading}
                          options={medFormOptions}
                          value={medFormOption}
                          onSelect={(value) => setMedFormOption(value)}
                          size={'large'}
                          placeholder="Select Drug Form"
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="mb-3 search-medication-strength aos" data-aos="fade-up">
                      <Form.Item name="drugStrength" rules={[{ required: true, message: "Medication strength is required" }]}
                                 help="Ex : 2 mg/ml">
                        <Select
                            style={{width: '100%', minHeight: 46}}
                            loading={medicationsLoading}
                            disabled={medicationsLoading || !medFormOption}
                            options={medStrengthOptions}
                            size={'large'}
                            placeholder="Select Drug Strength"
                        />
                      </Form.Item>
                    </div>
                    <div className="mb-3 search-medication-strength aos" data-aos="fade-up">
                      <Form.Item name="drugQuantity" rules={[{ required: true, message: "Medication strength is required" }]}
                                 help="Ex : 100">
                        <InputNumber
                            style={{width: '100%', minHeight: 52, lineHeight: '50px'}}
                            disabled={medicationsLoading || !medFormOption}
                            options={[]}
                            size={'large'}
                            placeholder="Select Quantity"
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="form-group justify-content-center aos" data-aos="fade-up">
                    <Button type="primary" htmlType="submit" size="large"
                            style={{width: 300}}
                            disabled={searchLoading}
                            icon={searchLoading ?
                                <LoadingOutlined /> :
                                <i className="fas fa-search" />}>
                      Search
                    </Button>
                  </div>
                </Form>

                {!searchLoading && searchMedicationName !== "" &&
                  <div className="search-result">
                    <h4>
                      {foundPharmacies.length > 0 && `Only `}
                      {<span className={foundPharmacies.length > 0 ? `pharmacies-count fw-bold` : `pharmacies-count text-warning fw-bold`}>
                        {foundPharmacies.length > 0 ? foundPharmacies.length : 'No'} pharmacies</span>} near <span className="fw-bold">{searchZipcode}</span> currently have <span className="fw-bold">{searchMedicationName}</span> in stock.
                    </h4>
                    {foundPharmacies.length === 0 &&
                    <h4 className="mb-3">
                      Pharmacies in Other Nearby States: <span className={`pharmacies-count ${foundOtherPharmacies.length === 0 ? 'text-danger' : ''}`}>{foundOtherPharmacies.length}</span>
                    </h4>
                    }
                    {(foundPharmacies.length > 0 || foundOtherPharmacies.length > 0) &&
                      <>
                        <h3 className="text-primary text-center mb-3">Get yours now before it’s out of stock!</h3>
                        <div className="row rx-request-steps">
                          <div className="col-lg-6 col-md-6">
                            <div className="work-step-box">
                              <div className="step-img">
                                <img src={medicalicon04} alt="prescription-filled"/>
                              </div>
                              <div className="step-desc">
                                <h6>Step 1</h6>
                                <p>Fill out this form to order your medication.</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="work-step-box">
                              <div className="step-img">
                                <img src={medicalicon03} alt="prescription-filled"/>
                              </div>
                              <div className="step-desc">
                                <h6>Step 2</h6>
                                <p>One of the nearby pharmacies above will contact you and fill your prescription today.</p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="search-result-box">
                            <RxRequestForm
                                pharmacies={foundPharmacies.length > 0 ? foundPharmacies.map((pharmacy) => pharmacy.id) : foundOtherPharmacies.map((pharmacy) => pharmacy.id)}
                                rxcui={form.getFieldValue('drugStrength')}
                                quantity={form.getFieldValue('drugQuantity')}
                                zipCode={searchZipcode}
                                drugName={searchMedicationName}
                            />
                        </div>
                      </>
                    }
                  </div>
                }

              </ConfigProvider>
            </div>
            {/* /Search */}
          </div>
        </div>
      </section>
      <Home2Footer />
    </div>
  );
};

export default SearchMedication;
