import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { doctordashboardprofile06 } from "../../../imagepath";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export const DashboardSidebar = () => {
  const authUser = useAuthUser();
  const pathnames = window.location.pathname;

  return (
    <>
    {/* Profile Sidebar */}
    <div className="profile-sidebar patient-sidebar profile-sidebar-new">
      <div className="widget-profile pro-widget-content">
        <div className="profile-info-widget">
          <Link to="/accounts/patients/profile" className="booking-doc-img">
            <img
              src={doctordashboardprofile06}
              alt="User Image"
            />
          </Link>
          <div className="profile-det-info">
            <h3>
              <Link to="/accounts/patients/profile">{authUser.patient?.first_name} {authUser.patient?.last_name}</Link>
            </h3>
            <span>
              {dayjs(authUser.patient?.birthdate).fromNow(true)} <i className="fa-solid fa-circle" />{authUser.patient?.phone}
            </span>
          </div>
        </div>
      </div>
      <div className="dashboard-widget">
        <nav className="dashboard-menu">
          <ul>
            <li className={pathnames.includes('/accounts/patients/dashboard') ? 'active' : ''}>
              <Link to="/accounts/patients/dashboard">
                <i className="fa-solid fa-shapes me-2" />
                <span>Dashboard</span>
              </Link>
            </li>
            <li className={pathnames.includes('/accounts/patients/profile') ? 'active' : ''}>
              <Link to="/accounts/patients/profile">
                <i className="fa-solid fa-user-pen me-2" />
                <span>Profile Settings</span>
              </Link>
            </li>
            <li className={pathnames.includes('/accounts/patients/contact-admin') ? 'active' : ''}>
              <Link to="/accounts/patients/contact-admin">
                <i className="fa-solid fa-question-circle me-2" />
                <span>Contact Admin</span>
              </Link>
            </li>
            <li className={pathnames.includes('/accounts/patients/change-password') ? 'active' : ''}>
              <Link to="/accounts/patients/change-password">
                <i className="fa-solid fa-key me-2" />
                <span>Change Password</span>
              </Link>
            </li>
            <li>
              <Link to="/login">
                <span>Logout</span>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    {/* /Profile Sidebar */}
  </>
  
  );
};
export default DashboardSidebar;
