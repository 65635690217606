import React, { useState } from "react";
import { Button } from "antd";
import {Link, useHistory} from "react-router-dom";

const NavLinks = () => {
  const history = useHistory();
  const [menu, setMenu] = useState(false);

  let pathnames = window.location.pathname;

  return (
    <>
      <li
        className={`has-submenu megamenu  ${
          pathnames.includes("index")
            ? "active"
            : "" || pathnames.includes("home")
            ? "active"
            : ""
        }`}
      >
        {pathnames.includes("patients") &&
          <Link
              className={'text-primary'}
              to={'/'}
          >
            Find a Pharmacy
          </Link>
        }
      </li>
    </>
  );
};

export default NavLinks;
