/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import AuthOutlet from '@auth-kit/react-router/AuthOutlet'
import {ConfigProvider} from 'antd';
// import config from "config";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
// import Header from "./components/header.jsx";
// import Footer from "./components/footer.jsx";
// import TopHeader from "./components/topheader.jsx";
import LoginContainer from "./components/login/login.jsx";
import Register from "./components/register/register.jsx";
import ForgotPassword from "./components/forgot-password";
import CreatePassword from "./components/create-password";

import Dashboard from "./components/patients/dashboard";
import Profile from "./components/patients/dashboard/profile";
import Password from "./components/patients/dashboard/password";
import DoctorDashboard from "./components/doctors/dashboard";
import RxTransaction from "./components/doctors/rxtransaction";
import SupportConversation from "./components/doctors/supportConversation";
import DoctorPassword from "./components/doctors/password";
import ProfileSetting from "./components/doctors/profilesetting";
import Contactus from "./components/pages/contactus/contactus";
//pharmacy
import DoctorRequest from "./components/doctors/doctorRequest/index.jsx";
import PatientInvoice from "./components/patients/patient-invoice/index.jsx";
import { base_path } from "./environment.jsx";
import Home2 from "./components/home/home-2/index.jsx";
import SearchMedication from "./components/home/home-2/searchmedication.jsx";
import RxRequest from "./components/home/home-2/rxRequest.jsx";
import RxRequestSuccess from "./components/home/home-2/rxRequestSuccess.jsx";
import PharmacyMessageSuccess from "./components/home/home-2/PharmacyMessageSuccess.jsx";

import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import PrivateRoute from "./core/route/privateRoute";
import SupportDashboard from "./components/staffs/dashboard/dashboard";
import DoctorMedications from "./components/doctors/doctorMedications";
import PharmacyRegister from "./components/doctors/register";
import PharmacyRegisterSuccess from "./components/doctors/register/registersuccess";
import PatientMessageSuccess from "./components/home/home-2/PatientMessageSuccess";

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

const AppContainer = function (props) {
  if (props) {

    useEffect(() => {
      var _mtm = window._mtm = window._mtm || [];
      _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
      var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
      g.async=true; g.src='https://cdn.matomo.cloud/prescriptionsquest.matomo.cloud/container_gI9F47Rg.js'; s.parentNode.insertBefore(g,s);

      const handleMouseMove = (event) => {
        const cursorInner = document.querySelector(".cursor-inner");
        const cursorOuter = document.querySelector(".cursor-outer");

        if (cursorOuter) {
          cursorOuter.style.transform = `translate(${event.clientX}px, ${event.clientY}px)`;
        }

        if (cursorInner) {
          cursorInner.style.transform = `translate(${event.clientX}px, ${event.clientY}px)`;
        }
      };

      const handleMouseEnter = () => {
        const cursorInner = document.querySelector(".cursor-inner");
        const cursorOuter = document.querySelector(".cursor-outer");

        if (cursorInner) {
          cursorInner.classList.add("s");
        }

        if (cursorOuter) {
          cursorOuter.classList.add("s");
        }
      };

      const handleMouseLeave = (event) => {
        const cursorInner = document.querySelector(".cursor-inner");
        const cursorOuter = document.querySelector(".cursor-outer");

        if (
          event.target.tagName !== "A" ||
          !event.target.closest(".cursor-pointer")
        ) {
          if (cursorInner) {
            cursorInner.classList.remove("cursor-hover");
          }

          if (cursorOuter) {
            cursorOuter.classList.remove("cursor-hover");
          }
        }
      };

      document.body.addEventListener("mousemove", handleMouseMove);
      document.body.addEventListener("mouseenter", handleMouseEnter);
      document.body.addEventListener("mouseleave", handleMouseLeave);

      const cursorInner = document.querySelector(".cursor-inner");
      const cursorOuter = document.querySelector(".cursor-outer");

      if (cursorInner) {
        cursorInner.style.visibility = "visible";
      }

      if (cursorOuter) {
        cursorOuter.style.visibility = "visible";
      }

      return () => {
        document.body.removeEventListener("mousemove", handleMouseMove);
        document.body.removeEventListener("mouseenter", handleMouseEnter);
        document.body.removeEventListener("mouseleave", handleMouseLeave);
      };
    }, []);

    return (
        <ConfigProvider
            theme={{
              token: {
                fontFamily: "\"Inter\", sans-serif",
                fontSize: 14,
                colorText: '#272b41',
                colorPrimary: '#1C5B91',
                colorPrimaryHover: '#1D7ED8',
                colorInfo: '#00d0f1',
                colorSuccess: '#7bb13c',
                colorError: '#e84646',
                colorWarning: '#ffbc34',
                colorBorder: "#cccccc",
                colorGreen: '#7bb13c',
                colorTextPlaceholder: "#757575",
                colorTextDescription: "#757575",
                colorTextTertiary: "#757575",
                controlHeight: 40,
                controlHeightLG: 50,
                primaryShadow: 'none',
                borderRadiusLG: 5,
                marginLG: 12,
              }
            }}
            >

          <Router basename={base_path}>
            <Switch>
              <Route path="/" exact component={Home2} />

              <Route path="/login" exact component={LoginContainer} />
              <Route path="/register" exact component={Register} />
              <Route
                  path="/accounts/forgot-password"
                  exact
                  component={ForgotPassword}
              />
              <Route
                  path="/accounts/create-password/:user/:token"
                  exact
                  component={CreatePassword}
              />

              <Route
                  path="/pharmacy-register"
                  exact
                  component={PharmacyRegister}
              />

              {/* Home routes */}
              <Route path="/" exact component={Home2} />

              <Route path="/search-medication/:drugName/:zipCode" component={SearchMedication}/>
              <Route path="/rx-request/:pharmacies/:rxcui/:quantity/:zipCode/:drugName" component={RxRequest}/>
              <Route path="/rx-request-success/:orderRef/:userId/:passwordToken" component={RxRequestSuccess}/>
              <Route path="/pharmacy-message-success" component={PharmacyMessageSuccess}/>
              <Route path="/pharmacy-register-success" component={PharmacyRegisterSuccess}/>
              <Route path="/patient-message-success" component={PatientMessageSuccess}/>

              <PrivateRoute path='/accounts/users/support-conversations/:conversationId' exact component={SupportConversation} />

              <PrivateRoute path='/accounts/pharmacies/contact-admin' exact component={Contactus} />
              <PrivateRoute path='/accounts/pharmacies/dashboard' exact component={DoctorDashboard} />
              <PrivateRoute path="/accounts/pharmacies/profile-setting" exact component={ProfileSetting} />
              <PrivateRoute path="/accounts/pharmacies/rx-transactions/:transactionId" exact component={RxTransaction} />
              <PrivateRoute
                  path="/accounts/pharmacies/rx-transactions"
                  exact
                  component={DoctorRequest}
              />
              <PrivateRoute
                  path="/accounts/pharmacies/medications"
                  exact
                  component={DoctorMedications}
              />
              <PrivateRoute
                  path="/accounts/pharmacies/change-password"
                  exact
                  component={DoctorPassword}
              />
              <PrivateRoute path='/accounts/patients/contact-admin' exact component={Contactus} />
              <PrivateRoute path='/accounts/patients/dashboard' exact component={Dashboard} />
              <PrivateRoute path="/accounts/patients/profile" exact component={Profile} />
              <PrivateRoute path="/accounts/patients/rx-transactions/:transactionId" exact component={RxTransaction} />
              <PrivateRoute
                  path="/accounts/patients/orders"
                  exact
                  component={PatientInvoice}
              />
              <PrivateRoute
                  path="/accounts/patients/change-password"
                  exact
                  component={Password}
              />

              <PrivateRoute path='/accounts/staffs/dashboard' exact component={SupportDashboard} />

            </Switch>
          </Router>
        </ConfigProvider>
    );
  }
  return null;
};

export default AppContainer;
