import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import Home2Header from "./header";
import Home2Footer from "./footer";
import { Link } from "react-router-dom";
import { AutoComplete, Select, ConfigProvider, Form, Input, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons"
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { specialities_01, specialities_02, specialities_03, specialities_04, specialities_05, doctor_01, doctor_02, doctor_03, doctor_04, doctor_05, doctor_06, doctor_07, doctor_08 } from "../../imagepath";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Header from "../../header";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import {
  heart_pulse
} from "../../imagepath";

const Home2 = () => {
  const isAuthenticated = useIsAuthenticated();
  const authUser = useAuthUser();

  const history = useHistory();
  const clinicSlider = {
    dots: true,
    autoplay: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    prevArrow: false,
    nextArrow: false,
    rows: 1,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 776,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  const doctorSlider = {
    dots: false,
    autoplay: false,
    infinite: true,
    slidesToShow: 3,
    speed: 500,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 776,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const featureSlider = {
    dots: true,
    infinite: true,
    centerMode: true,
    slidesToShow: 3,
    speed: 500,
    variableWidth: true,
    arrows: false,
    autoplay: false,
    responsive: [{
      breakpoint: 992,
      settings: {
        slidesToShow: 1
      }
    }]
  };

  const [nameOptions, setNameOptions] = useState([]);
  const [nameOptionsLoading, setNameOptionsLoading] = useState(false);

  AOS.init();
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  const handleScroll = () => {
    AOS.refresh();
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const onNameSearch = (searchText) => {
    if (searchText.length < 3)
      return;

    setNameOptions([])
    setNameOptionsLoading(true);

    axios.get('https://rxnav.nlm.nih.gov/REST/spellingsuggestions?name=' + searchText)
        .then(res => {
          if (res.data.suggestionGroup.suggestionList.suggestion) {
            setNameOptions(
                res.data.suggestionGroup.suggestionList.suggestion.map((sgg) => ({
                  value: sgg, label: sgg
                })))
          }
          setNameOptionsLoading(false);
        })
        .catch(err => console.log(err));
  }

  const [form] = Form.useForm();

  const onFinish = (values) => {
    history.push('/search-medication/' + values.drugName + '/' + values.zipCode)
  };

  return (
    <div className="main-wrapper">
      {
        isAuthenticated ?
          <Header/> :
          <Home2Header/>
      }
      {/* Home Banner */}
      <section className="section section-search">
        <div className="container-fluid">
          <div className="banner-wrapper">
            <div className="banner-header text-center aos" data-aos="fade-up">
              <h1>Relief starts here. Find your medication today</h1>
              <p>
                Locate nearby pharmacies that currently have the medication you need, with just one click.
              </p>
            </div>
            {/* Search */}
            <div className="search-box">
              <ConfigProvider
                  theme={{
                    components: {
                      Select: {
                        singleItemHeightLG: 46
                      },
                    },
                  }}>

                <Form
                    layout={'horizontal'}
                    form={form}
                    name="control-hooks"
                    onFinish={onFinish}
                    style={{ maxWidth: 600 }}
                >
                  <div className="mb-3 search-info aos" data-aos="fade-up">
                    <Form.Item
                        name="drugName"
                        rules={[{ required: true, message: "Name or brand is required" }]}
                        help={nameOptionsLoading ? "Loading..." : "Ex : Wegovy"}>
                      <AutoComplete
                          options={nameOptions}
                          style={{width: '100%', height: 'auto'}}
                          children={<input
                              type="text"
                              className="form-control"
                              placeholder="Generic Name, Brand Etc."/>}
                          onSearch={(text) => onNameSearch(text)}
                      />
                    </Form.Item>
                  </div>
                  <div className="mb-3 search-location aos" data-aos="fade-up">
                    <Form.Item name="zipCode" rules={[{ required: true, message: "Zip code is required" }]}
                               help="Based on your Location"
                    >
                      <Input
                          type="text"
                          className="form-control"
                          placeholder="Zip code"
                      />
                    </Form.Item>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary search-btn mt-0 aos"
                    data-aos="fade-up"
                  >
                    <i className="fas fa-search" />
                  </button>
                </Form>
              </ConfigProvider>
            </div>
            {/* /Search */}
          </div>
          <div className="steps-to-follow">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 mx-auto aos" data-aos="fade-up">
                  <div className="section-header-one section-header-slider text-center">
                    <h2 className="section-title">Get your meds in 3 easy steps</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-4 aos" data-aos="fade-up">
                  <div className="box-detail">
                    <div className="steps-list-box">
                      <div className="steps-list-img">
                        <span>1</span>
                        <img src={heart_pulse} className="img-fluid" alt="heart-pulse"/>
                      </div>
                      <h6>Drug Search</h6>
                      <p>Search by drug name and zip code.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 aos" data-aos="fade-up">
                  <div className="box-detail">
                    <div className="steps-list-box">
                      <div className="steps-list-img">
                        <span>2</span>
                        <img src={heart_pulse} className="img-fluid" alt="heart-pulse"/>
                      </div>
                      <h6>Transfer To Pharmacy</h6>
                      <p>Transfer your script to a nearby pharmacy that has your meds in stock.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 aos" data-aos="fade-up">
                  <div className="box-detail">
                    <div className="steps-list-box">
                      <div className="steps-list-img">
                        <span>3</span>
                        <img src={heart_pulse} className="img-fluid" alt="heart-pulse"/>
                      </div>
                      <h6>Fill Prescription</h6>
                      <p>Our local pharmacy partner retrieves and fills your prescription in just minutes.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section section-blogs">
        <div className="container-fluid">
          {/* Section Header */}
          <div className="section-header text-center aos" data-aos="fade-up">
            <h2>Why Choose Us</h2>
            {/*<p className="sub-title">*/}
            {/*  Prescription Quest is marketplace for independent pharmacies. We bring patients with prescriptions to fill and independent pharmacies together.*/}
            {/*</p>*/}
          </div>
          {/* /Section Header */}
          <div className="row blog-grid-row">
            <div className="col-md-4 col-lg-4 col-sm-12 aos" data-aos="fade-up">
              {/* Blog Post */}
              <div className="blog grid-blog">
                <div className="blog-content">
                  <h3 className="blog-title">
                    <Link to="/blog/blog-details">
                      1. Real-Time Inventory Access
                    </Link>
                  </h3>
                  <p className="mb-0">
                    Prescription Quest provides patients with up-to-date information on medication availability at nearby pharmacies. This feature eliminates the frustration of visiting multiple pharmacies only to find that their required medications are out of stock. With accurate, real-time data, patients can quickly locate a pharmacy that has their prescription in stock, ensuring timely access to necessary treatments.
                  </p>
                </div>
              </div>
              {/* /Blog Post */}
            </div>
            <div className="col-md-4 col-lg-4 col-sm-12 aos" data-aos="fade-up">
              {/* Blog Post */}
              <div className="blog grid-blog">
                <div className="blog-content">
                  <h3 className="blog-title">
                    <Link to="/blog/blog-details">
                      2. User-Friendly Search Features
                    </Link>
                  </h3>
                  <p className="mb-0">
                    The platform's intuitive design allows users to search for medications and pharmacies based on their location. This streamlined process helps patients find the nearest pharmacies that carry their needed medications, reducing travel time and stress. By simply entering their location and medication, patients can access a list of local pharmacies that can meet their needs.
                  </p>
                </div>
              </div>
              {/* /Blog Post */}
            </div>
            <div className="col-md-4 col-lg-4 col-sm-12 aos" data-aos="fade-up">
              {/* Blog Post */}
              <div className="blog grid-blog">
                <div className="blog-content">
                  <h3 className="blog-title">
                    <Link to="/blog/blog-details">
                      3. Comprehensive Pharmacy Profiles
                    </Link>
                  </h3>
                  <p className="mb-0">
                    Prescription Quest offers detailed profiles for each pharmacy, including services offered, hours of operation, and specializations such as compounding. This information helps patients choose the most suitable pharmacy for their specific needs, whether they require a standard prescription refill or a specialized compounded medication. The platform empowers patients to make informed decisions about their healthcare, enhancing their overall experience and satisfaction.
                  </p>
                </div>
              </div>
              {/* /Blog Post */}
            </div>
          </div>
        </div>
      </section>
      <Home2Footer />
    </div>
  );
};

export default Home2;
